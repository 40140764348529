const defaultState = () => {
  return {
    menu: null,
    error: null,

  };
};

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
  },
  mutations: {
    set(state, properties) {
      for (const key in properties) {
        if (state.hasOwnProperty(key)) state[key] = properties[key]
      }
    },
    reset(state) {
      Object.assign(state, defaultState());
    },
  },
  actions: {}
};
