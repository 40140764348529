import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

Vue.use(Router);
Vue.use(VueMeta);

import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import Register from "./views/Register.vue";
import NotFound from "./views/NotFound.vue";
import VerifyMobikwik from "./views/VerifyMobikwik.vue";

import Sessions from "./views/Sessions.vue";
import Wallets from "./views/Wallets.vue";
import Billing from "./views/owner/Billing.vue";
import Settings from "./views/Settings.vue";
import GettingStarted from "./views/GettingStarted.vue";
import OutletManagement from "./views/owner/OutletManagement.vue";
import DeviceNetworkManagement from "./views/owner/DeviceNetworkManagement/DeviceNetworkManagement.vue";
import AccessPlans from "./views/owner/AccessPlans.vue";
import Terms from "./views/Terms.vue";
import Privacy from "./views/Privacy.vue";
import Setup from "./views/owner/Setup.vue";
import AdvanceSettings from "./views/owner/AdvanceSettings.vue";
import BleConfiguration from "./views/owner/BleConfiguration.vue";
import PaymentPlanForm from "./views/owner/PaymentPlanForm/PaymentPlanForm.vue";
import ActivateDevice from "./views/ActivateDevice.vue";
import Test from "./views/Test.vue";
import AccountSelection from "./views/owner/AccountSelection.vue";

import OwnerGetStarted from "./views/owner/GetStarted/GetStarted.vue";

import ManagerView from "./views/admin/ManagerView.vue";
import DeviceView from "./views/admin/deviceview/DeviceView.vue";
import UserView from "./views/admin/UserView.vue";
import InfoAndLogs from "./views/admin/deviceview/InfoAndLogs.vue";
import HeartbeatAnalytics from "./views/admin/deviceview/HeartbeatAnalytics.vue";
import ConfigureDeviceSubscription from "./views/admin/ConfigureDeviceSubscription.vue";
import OwnerConfigureSubscription from "./views/owner/OwnerConfigureSubscription.vue";
import DeviceSubscriptionPlan from "./views/admin/DeviceSubscriptionPlan.vue";
import LoadBalancing from "./views/owner/LoadBalancing/LoadBalancing.vue"
import LoadBalancingForm from "./views/owner/LoadBalancing/LoadBalancingForm.vue"

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Landing,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/login/:status",
      name: "registered",
      component: Login,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
    },
    {
      path: "/forgot-password/:status",
      name: "new-password",
      component: ForgotPassword,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/sessions",
      name: "sessions",
      component: Sessions,
    },
    {
      path: "/wallets",
      name: "wallets",
      component: Wallets,
    },
    {
      path: "/service-subscription",
      name: "service-subscription",
      component: Billing,
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
    },
    {
      path: "/getting-started",
      name: "getting-started",
      component: GettingStarted,
    },
    {
      path: "/outlet-management",
      name: "outlet-management",
      component: OutletManagement,
    },
    {
      path: "/device-network-management",
      name: "device-network-management",
      component: DeviceNetworkManagement,
    },
    {
      path: "/access-plans",
      name: "access-plans",
      component: AccessPlans,
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: Terms,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: Privacy,
    },
    {
      path: "/setup",
      name: "setup",
      component: Setup,
    },
    {
      path: "/advance-settings",
      name: "advance-settings",
      component: AdvanceSettings,
    },
    {
      path: "/ble-configuration",
      name: "ble-configuration",
      component: BleConfiguration,
    },
    {
      path: "/payment-plan-form",
      name: "payment-plan-form",
      component: PaymentPlanForm,
    },
    {
      path: "/payment-plan-form/:id",
      name: "payment-plan-form-edit",
      component: PaymentPlanForm,
    },
    {
      path: "/activate-device/:plug_id",
      name: "activate-device",
      alias: "/activate-plug",
      component: ActivateDevice,
    },
    {
      path: "/verify-mobikwik",
      name: "verify-mobikwik",
      component: VerifyMobikwik,
    },

    // alias routes
    {
      path: "/owner",
      name: "owner-dashboard",
      component: Landing,
    },
    {
      path: "/owner/login",
      name: "owner-login",
      component: Login,
    },
    {
      path: "/owner/login/:status",
      name: "owner-registered",
      component: Login,
    },
    {
      path: "/owner/forgot-password",
      name: "owner-forgot-password",
      component: ForgotPassword,
    },
    {
      path: "/owner/forgot-password/:status",
      name: "owner-new-password",
      component: ForgotPassword,
    },
    {
      path: "/owner/register",
      name: "owner-register",
      component: Register,
    },
    {
      path: "/owner/verify-mobikwik",
      name: "owner-verify-mobikwik",
      component: VerifyMobikwik,
    },
    {
      path: "/owner/wallets",
      name: "owner-wallets",
      component: Wallets,
    },
    {
      path: "/owner/service-subscription",
      name: "owner-service-subscription",
      component: Billing,
    },
    {
      path: "/owner/settings",
      name: "owner-settings",
      component: Settings,
    },
    {
      path: "/owner/getting-started",
      name: "owner-getting-started",
      component: GettingStarted,
    },
    {
      path: "/owner/outlet-management",
      name: "owner-outlet-management",
      component: OutletManagement,
    },
    {
      path: "/owner/device-network-management",
      name: "owner-device-network-management",
      component: DeviceNetworkManagement,
    },
    {
      path: "/owner/access-plans",
      name: "owner-access-plans",
      component: AccessPlans,
    },
    {
      path: "/owner/terms-of-service",
      name: "owner-terms-of-service",
      component: Terms,
    },
    {
      path: "/owner/privacy-policy",
      name: "owner-privacy-policy",
      component: Privacy,
    },
    {
      path: "/owner/setup",
      name: "owner-setup",
      component: Setup,
    },
    {
      path: "/owner/advance-settings",
      name: "owner-advance-settings",
      component: AdvanceSettings,
    },
    {
      path: "/owner/ble-configuration",
      name: "owner-ble-configuration",
      component: BleConfiguration,
    },
    {
      path: "/owner/payment-plan-form",
      name: "owner-payment-plan-form",
      component: PaymentPlanForm,
    },
    {
      path: "/owner/payment-plan-form/:id",
      name: "owner-payment-plan-form-edit",
      component: PaymentPlanForm,
    },
    {
      path: "/owner/get-started",
      name: "owner-get-started",
      component: OwnerGetStarted,
    },
    {
      path: "/owner/configure-subscription", 
      name: "owner-configure-subscription",
      component: OwnerConfigureSubscription,
    },
    {
      path: "/owner/account-selection", 
      name: "owner-account-selection",
      component: AccountSelection,
    },
    {
      path: "/test",
      name: "test",
      component: Test,
    },
    {
      path: "/owner/test",
      name: "owner-test",
      component: Test,
    },

    {
      path: "/admin",
      name: "admin-dashboard",
      component: Landing,
    },
    {
      path: "/admin/login",
      name: "admin-login",
      component: Login,
    },
    {
      path: "/admin/managerview",
      name: "admin-managerview",
      component: ManagerView,
    },
    {
      path: "/admin/managerview-dashboard",
      name: "admin-managerview-dashboard",
      component: Landing,
    },
    {
      path: "/admin/managerview-outlet-management",
      name: "admin-managerview-outlet-management",
      component: OutletManagement,
    },
    {
      path: "/admin/managerview-device-network-management",
      name: "admin-managerview-device-network-management",
      component: DeviceNetworkManagement,
    },
    {
      path: "/admin/managerview-paymentplans",
      name: "admin-managerview-paymentplans",
      component: AccessPlans,
    },
    {
      path: "/admin/managerview-payment-plan-form",
      name: "admin-managerview-payment-plan-form",
      component: PaymentPlanForm,
    },
    {
      path: "/admin/managerview-payment-plan-form/:id",
      name: "admin-managerview-payment-plan-form-edit",
      component: PaymentPlanForm,
    },
    {
      path: "/admin/managerview-wallets",
      name: "admin-managerview-wallets",
      component: Wallets,
    },
    {
      path: "/admin/managerview-subscription",
      name: "admin-managerview-subscription",
      component: Billing,
    },
    {
      path: "/admin/managerview-configuration",
      name: "admin-managerview-configuration",
      component: Settings,
    },
    {
      path: "/admin/userview-configuration",
      name: "admin-userview-configuration",
      component: Settings,
    },
    {
      path: "/admin/deviceview",
      name: "admin-deviceview",
      component: DeviceView,
    },
    {
      path: "/admin/deviceview/info-and-logs",
      name: "admin-deviceview-info-and-logs",
      component: InfoAndLogs,
    },
    {
      path: "/admin/deviceview/heartbeat-analytics",
      name: "admin-deviceview-heartbeat-analytics",
      component: HeartbeatAnalytics,
    },
    {
      path: "/admin/deviceview-dashboard",
      name: "admin-deviceview-dashboard",
      component: Landing,
    },
    {
      path: "/admin/userview",
      name: "admin-userview",
      component: UserView,
    },
    {
      path: "/admin/userview-dashboard",
      name: "admin-userview-dashboard",
      component: Landing,
    },
    {
      path: "/admin/userview-sessions",
      name: "admin-userview-sessions",
      component: Sessions,
    },
    {
      path: "/admin/userview-wallets",
      name: "admin-userview-wallets",
      component: Wallets,
    },
    {
      path: "/admin/settings",
      name: "admin-settings",
      component: Settings,
    },
    {
      path: "/admin/configure-device-subscriptions",
      name: "admin-configure-device-subscriptions",
      component: ConfigureDeviceSubscription,
    },
    {
      path: "/admin/configure-device-subscriptions-time",
      name: "admin-configure-device-subscriptions-time",
      component: ConfigureDeviceSubscription,
    },
    {
      path: "/admin/configure-device-subscriptions-plan",
      name: "admin-configure-device-subscriptions-plan",
      component: DeviceSubscriptionPlan,
    },
    {
      path: "/owner/load-balancing",
      name: "owner-load-balancing",
      component: LoadBalancing,
    },
    { 
      path: "/owner/load-balancing-form",
      name: "owner-load-balancing-form",
      component: LoadBalancingForm,
    },
    { 
      path: "/owner/load-balancing-form/:id",
      name: "owner-load-balancing-form-edit",
      component: LoadBalancingForm,
    },
    {
      path: "/admin/managerview-load-balancing",
      name: "admin-managerview-load-balancing",
      component: LoadBalancing
    },
    { 
      path: "/admin/managerview-load-balancing-form",
      name: "admin-managerview-load-balancing-form",
      component: LoadBalancingForm,
    },
    { 
      path: "/admin/managerview-load-balancing-form/:id",
      name: "admin-managerview-load-balancing-form-edit",
      component: LoadBalancingForm,
    },
    {
      path: "*",
      component: NotFound,
    },
  ],
});
