import Vue from "vue";
import Api from "./../../library/apis/Api";
import SortHelpers from "./../../library/helpers/SortHelpers";
import { DeviceHelpers } from "@/library/helpers";

export default {
  namespaced: true,
  state: {
    all: null,
  },
  getters: {
    plugs: (state, getters, rootState) => {
      if (!state.all) return [];
      let plugs = state.all;
      const activeSessions = rootState.sessions.filter((session) => !session.endtime);

      plugs.map((plug) => {
        plug.active = !!activeSessions.find((a) => a.plug_id === plug.id);
        plug.active_state = plug.active || plug.plugmode === 1;

        plug.plan = null;
        if (!!plug.plan_id) plug.plan = rootState.plans.find((plan) => plan.id === plug.plan_id);
        if (plug.plan && typeof plug.plan.params == "string") plug.plan.params = JSON.parse(plug.plan.params);

        plug.access_settings = plug.ispublic ? "Public" : "Private";
        plug.access_plan = plug.ispublic && plug.plan ? plug.plan.params.description : "";

        plug.mode = "Always Off";
        plug.color = "#f44336";
        if (plug.plugmode == 1) {
          plug.mode = "Always On";
          plug.color = "#00acc1";
        } else if (plug.plugmode == 2) {
          plug.mode = "Smart";
          plug.color = "#FFA500";
        } else if (plug.plugmode >= 3) {
          plug.mode = "Bluetooth";
          plug.color = "#000";
        }

        const start = Vue.moment.unix(plug.start_timestamp).tz(rootState.Global.timezone);
        const expiry = Vue.moment.unix(plug.expiry_timestamp).tz(rootState.Global.timezone);
        const today = Vue.moment().tz(rootState.Global.timezone);
        const diff = expiry.diff(today, "days");
        plug.auto = plug.payment_wallet_id ? "ON" : "OFF";
        plug.auto_class = plug.payment_wallet_id ? "green--text" : "orange--text";
        plug.wallet = null;
        if (!!plug.payment_wallet_id) plug.wallet = rootState.wallets.find((wallet) => wallet.id == plug.payment_wallet_id);
        plug.start = start.format("DD-MMM-YYYY hh:mm:ss A");
        plug.expiry = expiry.format("DD-MMM-YYYY hh:mm:ss A");
        plug.diff = diff;

        plug.expired = "bill-expired";
        plug.remaining = "Disabled";
        plug.remaining_raw = 0;
        if (plug.state != 0) {
          plug.expired = diff <= 7 ? "bill-warning" : diff <= 30 ? "bill-notify" : "";
          plug.remaining_raw = plug.diff;
          plug.remaining = plug.diff < 1 ? `${Math.abs(plug.diff)} Days Expired` : plug.diff + " Days";
        }

        const { communication, communication_type } = DeviceHelpers.parseSerialNumber(plug.serial_no || "") || {}
        plug.communication = communication;
        plug.communication_type = communication_type

        plug.logs = [];
        plug.expanded = false;
        plug.busy = false;

        return plug;
      });
      // if (filters) return PlugHelpers.filter(plugs, filters);
      return plugs.sort(SortHelpers.compareValues("identifier"));
    },
  },
  mutations: {
    set(state, plugs) {
      state.all = plugs;
    },
  },
  actions: {
    get({ commit, rootState, rootGetters }) {
      return new Promise((resolve, reject) => {
        let apiPrefix = "";
        if (rootState.Auth.role == "admin") apiPrefix = "admin/";
        let formData = {};
        if (rootGetters["Admin/viewAsOwner"]) {
          formData = { ownerUsername: rootGetters["Admin/viewAsOwner"].username };
          apiPrefix += "owner";
        } else if (rootGetters["Admin/viewAsUser"]) {
          formData = { username: rootGetters["Admin/viewAsUser"].username };
          apiPrefix += "user";
        }
        Api.plugs(apiPrefix, formData)
          .then((plugs) => {
            commit("set", plugs || []);
            resolve(plugs || []);
          })
          .catch(reject);
      });
    },
    refreshPlugCards({ state }, plugIds) {
      console.log("refreshPlugCards", plugIds);
    },
  },
};
