export default {
  namespaced: true,
  state: {},
  getters: {
    hiddenPayments: (_, __, rootState) => { 
      if (rootState.Admin.managerview) return (rootState.Admin.managerview.preferences || {}).hiddenPayments || []
      return (rootState.Global.preferences || {}).hiddenPayments || []
    }, 
    visiblePayments: (_, getters, ___, rootGetters) => { 
      return rootGetters["accessPlans"].filter(w => !getters["hiddenPayments"].includes(w.id))
    }
  },
  mutations: {},
  actions: {},
};
