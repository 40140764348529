import Vue from "vue";
import Vuex from "vuex";
import dates from "./../dates";
import "./../version";
import TimeHelpers from "./../library/helpers/TimeHelpers";
import SortHelpers from "./../library/helpers/SortHelpers";
import Auth from "./modules/Auth";
import Admin from "./modules/Admin";
import Log from "./modules/Log";
import Session from "./modules/Session";
import Global from "./modules/Global";
import Plug from "./modules/Plug";
import Api from "./../library/apis/Api";
import Filter from "./modules/Filter";
import AccessPlanHelpers from "../library/helpers/AccessPlanHelpers";
import PaymentPlanForm from "./modules/PaymentPlanForm"
import Wallet from "./modules/Wallet"
import PaymentPlan from "./modules/PaymentPlan"
import { cloneDeep } from "lodash-es"
import Device from "./modules/Device"
import timezones from "./../data/timezones.json";
import TimezoneHelper from "@/library/helpers/TimezoneHelper";
import { DeviceHelpers } from "@/library/helpers";
import LoadBalancing from "./modules/LoadBalancing"
import OwnerProxy from "./modules/OwnerProxy";
import { USER_ROLES } from "../constants"

Vue.use(Vuex);

function getSelectedBleDevice(payload) {
  let email = localStorage.getItem("email");
  if (payload.hasOwnProperty("email")) email = payload.email;
  const bleProperties = {
    activated: false,
    credential: {},
    device_id: payload.device_id,
    sessions: [],
    session_active: false,
    syncTime: 0,
    startTime: null,
    endTime: null,
    chunks: null,
    signature: null,
    paymentPlan: {},
    ownerPublicProfile: {
      name: null,
      email: null,
      phone: null,
      message: null,
    },
  };
  let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
  if (!cache) {
    cache = {};
    cache[email] = { bleDevices: [] };
    cache[email].bleDevices.push(bleProperties);
  }
  if (typeof cache[email] == "undefined") {
    cache[email] = { bleDevices: [] };
  }
  if (!cache[email].hasOwnProperty("bleDevices")) {
    cache[email].bleDevices = [];
    cache[email].bleDevices.push(bleProperties);
  }
  let selectedBleDevice = null;
  for (let index = 0; index < cache[email].bleDevices.length; index++) {
    const bleDevice = cache[email].bleDevices[index];
    if (bleDevice.device_id == payload.device_id) {
      selectedBleDevice = {
        index: index,
        data: bleDevice,
      };
      break;
    }
  }
  if (!selectedBleDevice) {
    cache[email].bleDevices.push(bleProperties);
    selectedBleDevice = {
      index: cache[email].bleDevices.length - 1,
      data: bleProperties,
    };
  }
  localStorage.setItem("__plugzio_storage__", JSON.stringify(cache));
  return selectedBleDevice;
}

function setBleDevice(state, selectedBleDevice, email = null) {
  if (!email) email = localStorage.getItem("email");
  let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
  if (typeof cache[email] == "undefined") {
    cache[email] = {
      bleDevices: [],
    };
  }
  cache[email].bleDevices[selectedBleDevice.index] = selectedBleDevice.data;
  state.bleDevices = cache[email].bleDevices;
  localStorage.setItem("__plugzio_storage__", JSON.stringify(cache));
}

const SESSION_STATUS = {
  OFF: 0,
  ON: 1,
  STALE: 2,
};

export default new Vuex.Store({
  modules: {
    Admin,
    Auth,
    Log,
    Session,
    Global,
    Plug,
    Filter,
    PaymentPlanForm,
    Wallet,
    PaymentPlan,
    Device,
    LoadBalancing,
    OwnerProxy
  },
  state: {
    bleActivation: {
      activeProcess: null,
      activeBleDevice: null,
      forceStopProcess: false,
      loading: false,
      busy: false,
      device: null,
      deviceConnected: false,
      forceConnectManually: false,
      showCloseConfirmation: false,
      plug_identifier: null,
      connectedPlugIdentifier: null,
      retry: 0,
      modal: {
        loading: null,
        error: null,
        show: false,
        process: null,
        message: null,
        cancelButton: null,
        okButton: null,
      },
      response: {
        failed: 0,
        log: null,
        obj: null,
        response: null,
      },
      rxCharacteristic: null,
      startSession: false,
      tokens: {
        token: null,
        expiry: null,
        userId: null,
        papSnapshotId: null,
      },
      activeCommand: {
        key: null,
        message: null,
        bleCommandEnum: null,
        bleCommandParams: {},
      },
    },
    bleDevices: [],
    ownerBleCredentials: {},
    ownerBleSessions: [],
    loading: false,
    responsive: false,
    snackbar: {
      show: false,
      message: null,
      timeout: 5000,
    },
    time: {
      since: null,
      till: null,
    },
    count: {
      plugs: null,
      plans: null,
      wallets: null,
    },
    timeSpan: [],
    outletHighlight: null,
    plugEdit: null,
    plugOfflineEdit: null,
    showOwnerRegisterDevice: null,
    accessPlanEdit: null,
    walletEdit: null,
    plugAccessUser: null,
    withPlans: null,
    plugs: [],
    plans: [],
    stats: [],
    sessions: [],
    revenueTotal: [],
    initialized: false,
    universalLink: null,
    firebaseToken: null,
    firebaseMessage: null,
    plugClosestExpirationTimestamp: null,
    showFeedback: false,
    wallets: [],
    walletItems: [],
    walletTransactions: [],
    expenses: [],
    version: version,
    deviceRegistrationSecretToken: null,
  },
  mutations: {
    SET_BLE_ACTIVATION(state, payload) {
      for (const property of Object.keys(payload)) {
        state.bleActivation[property] = payload[property];
      }
    },
    SET_BLE_ACTIVATION_MODAL(state, payload) {
      for (const property of Object.keys(payload)) {
        state.bleActivation.modal[property] = payload[property];
      }
    },
    SET_BLE_ACTIVATION_ACTIVE_COMMAND(state, payload) {
      for (const property of Object.keys(payload)) {
        state.bleActivation.activeCommand[property] = payload[property];
      }
    },
    SET_BLE_CREDENTIAL(state, payload) {
      let selectedBleDevice = getSelectedBleDevice(payload);
      if (!selectedBleDevice.data.hasOwnProperty("credential")) {
        selectedBleDevice.data.credential = {};
      }
      selectedBleDevice.data.credential = payload.bleCredential;
      setBleDevice(state, selectedBleDevice);
    },
    SET_BLE_DEVICE(state, payload) {
      let email = localStorage.getItem("email");
      if (payload.hasOwnProperty("email")) email = payload.email;
      let selectedBleDevice = getSelectedBleDevice(payload);
      for (const property in payload.properties) {
        if (!selectedBleDevice.data.hasOwnProperty(payload)) {
          selectedBleDevice.data[property] = {};
        }
        selectedBleDevice.data[property] = payload.properties[property];
      }
      setBleDevice(state, selectedBleDevice, email);
    },
    SET_BLE_DEVICES(state, payload) {
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
      const email = localStorage.getItem("email");
      if (typeof cache[email] == "undefined") cache[email] = { bleDevices: [] };
      if (!cache[email].hasOwnProperty("bleDevices")) {
        cache[email].bleDevices = [];
      }
      cache[email].bleDevices = state.bleDevices = payload;
      localStorage.setItem("__plugzio_storage__", JSON.stringify(cache));
    },
    SET_BLE_RESPONSE(state, payload) {
      for (const property of Object.keys(payload)) {
        state.bleActivation.response[property] = payload[property];
      }
    },
    SET_BLE_SYNC_TIME(state, payload) {
      let selectedBleDevice = getSelectedBleDevice(payload);
      if (!selectedBleDevice.data.hasOwnProperty("syncTime")) {
        selectedBleDevice.data.syncTime = 0;
      }
      // let syncTime = payload.syncTime ? payload.syncTime : selectedBleDevice.data.syncTime;
      let syncTime = payload.syncTime;
      selectedBleDevice.data.syncTime = syncTime;
      setBleDevice(state, selectedBleDevice);
    },
    SET_OWNER_BLE_CREDENTIAL(state, payload) {
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage_owner_ble_credentials__"));
      if (!cache) cache = {};
      let email = localStorage.getItem("email");
      if (payload.hasOwnProperty("email")) email = payload.email;

      if (!cache.hasOwnProperty(email)) cache[email] = {};
      cache[email][payload.deviceId] = payload.credential;
      state.ownerBleCredentials = cache[email];
      localStorage.setItem("__plugzio_storage_owner_ble_credentials__", JSON.stringify(cache));
    },
    SET_OWNER_BLE_SESSIONS(state, payload) {
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage_owner_ble_sessions__"));
      if (!cache) cache = {};
      let email = localStorage.getItem("email");
      if (payload.hasOwnProperty("email")) email = payload.email;

      if (!cache.hasOwnProperty(email)) cache[email] = {};
      cache[email][payload.deviceId] = payload.sessions;
      state.ownerBleSessions = cache[email];
      localStorage.setItem("__plugzio_storage_owner_ble_sessions__", JSON.stringify(cache));
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_RESPONSIVE(state, payload) {
      state.responsive = payload;
    },
    SET_SNACKBAR(state, payload) {
      if (payload.message) {
        state.snackbar.message = payload.message;
        state.snackbar.show = true;
      }
    },
    SET_SNACKBAR_HIDE(state) {
      state.snackbar.show = false;
    },
    SET_TIME(state, payload) {
      state.time = payload;
    },
    SET_TIME_SPAN(state, payload) {
      state.timeSpan = payload;
    },
    SET_COUNT_PLUGS(state, payload) {
      state.count.plugs = payload;
    },
    SET_COUNT_PLANS(state, payload) {
      state.count.plans = payload;
    },
    SET_COUNT_WALLETS(state, payload) {
      state.count.wallets = payload;
    },
    SET_COUNT_USER_WALLETS(state, payload) {
      state.count.wallets = payload;
    },
    SET_OUTLET_HIGHLIGHT(state, payload) {
      state.outletHighlight = payload;
    },
    SET_PLUG_EDIT(state, payload) {
      state.plugEdit = payload;
    },
    SET_PLUG_OFFLINE_EDIT(state, payload) {
      state.plugOfflineEdit = payload;
    },
    SET_SHOW_OWNER_REGISTER_PLUG(state, payload) {
      state.showOwnerRegisterDevice = payload;
    },
    SET_WALLETS(state, payload) {
      state.wallets = payload.map((o) => {
        o.name = `${o.description} (${o.currency})`;
        return o;
      });
    },
    SET_WALLET_ITEMS(state, payload) {
      state.walletItems = payload;
    },
    SET_WALLET_TRANSACTIONS(state, payload) {
      state.walletTransactions = payload;
    },
    SET_SPESIFIC_WALLET_TRANSACTIONS(state, payload) {
      let selectedWallet = state.walletTransactions.filter((wallet) => {
        return wallet.id == payload.walletId;
      })[0];
      if (typeof selectedWallet == "undefined") {
        return false;
      }
      selectedWallet.transactions = payload.transactions;
    },
    SET_WITH_PLANS(state, payload) {
      state.withPlans = payload;
    },
    SET_PLUGS(state, payload) {
      state.plugs = payload;
    },
    SET_PLUG_CLOSEST_EXPIRATION_TIMESTAMP(state, payload) {
      if (payload == null) {
        state.plugClosestExpirationTimestamp = null;
        return;
      }
      const current_timestamp = Math.floor(new Date().getTime() / 1000);
      const expiration_threshold = current_timestamp + 2592000; // + 30 days
      const plug_expiration_timestamps = payload
        .filter((plug) => {
          return plug.state != 0 && plug.payment_wallet_id == null && plug.expiry_timestamp < expiration_threshold && plug.expiry_timestamp > current_timestamp;
        })
        .map((plug) => plug.expiry_timestamp);

      if (!plug_expiration_timestamps.length) {
        state.plugClosestExpirationTimestamp = null;
        return;
      }

      const closest_expiration_timestamp = Math.min(...plug_expiration_timestamps);
      state.plugClosestExpirationTimestamp = closest_expiration_timestamp;

      const is_dismissed = localStorage.getItem("__plugzio_plug_expiration_notification_dismissed__");
      if (is_dismissed === "true") return;
      localStorage.setItem("__plugzio_plug_expiration_notification_dismissed__", false);
    },
    SET_PLANS(state, payload) {
      state.plans = payload;
    },
    SET_ACCESS_PLAN_EDIT(state, payload) {
      state.accessPlanEdit = payload;
    },
    SET_WALLET_EDIT(state, payload) {
      state.walletEdit = payload;
    },
    SET_PLUG_ACCESS_USER_EDIT(state, payload) {
      state.plugAccessUser = payload;
    },
    SET_STATS(state, payload) {
      state.stats = payload;
    },
    SET_EXPENSES(state, payload) {
      state.expenses = payload;
    },
    SET_SESSIONS(state, payload) {
      state.sessions = payload
    },
    SET_REVENUE_TOTAL(state, payload) {
      state.revenueTotal = payload;
    },
    RESET_COUNT(state) {
      state.count.plugs = null;
      state.count.plans = null;
      state.count.wallets = null;
      state.wallets = [];
      state.plans = [];
    },
    RESET_WITH_PLANS(state) {
      state.withPlans = null;
    },
    SET_UNIVERSAL_LINK(state, payload) {
      state.universalLink = payload;
    },
    SET_FIREBASE_TOKEN(state, payload) {
      state.firebaseToken = payload;
    },
    SET_FIREBASE_MESSAGE(state, payload) {
      state.firebaseMessage = payload;
    },
    SHOW_FEEDBACK(state, payload) {
      state.showFeedback = payload;
    },
    SET_DEVICE_REGISTRATION_SECRET_TOKEN(state, payload) {
      state.deviceRegistrationSecretToken = payload;
    },
    RESET(state) {
      state.count.plugs = null;
      state.count.plans = null;
      state.count.wallets = null;
      state.wallets = [];
      state.plans = [];
      state.withPlans = null;
      state.sessions = [];
      state.plugs = []
    },
    SET_STATE(state, payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          state[key] = payload[key];
        }
      }
    }
  },
  getters: {
    accessPlans(state) {
      let plans = cloneDeep(state.plans);
      if (!plans) return [];
      plans.map((plan) => {
        if (typeof plan.params == "string") plan.params = JSON.parse(plan.params);
        let walletId = plan.params.walletId;
        plan.wallet = state.wallets.find((wallet) => wallet.id === walletId);
        plan.wallet_name = plan.wallet ? plan.wallet.name : "";
        plan.params.whRate = (plan.params.whRate * 1000000000) / 1000000;
        if (!plan.params.hasOwnProperty("description")) plan.params.description = "Payment Plan " + plan.id;
        if (!plan.params.hasOwnProperty("hRate")) plan.params.hRate = 0.0;
        plan.name = plan.params.description;
        plan.description = plan.name;

        plan.searchable = AccessPlanHelpers.getSearchable(plan);

        /** Sorting variables */
        plan.sort_variable_timezone = -99
        if (plan.params.hasOwnProperty("timezone")) {
          const selectedTimezone = timezones.find(tm => tm.value == plan.params.timezone);
          plan.sort_variable_timezone = selectedTimezone ? TimezoneHelper.getNumberOffsetFromFullTimezone(selectedTimezone.text) : null;
        }
        /** End Sorting variables */

        return plan;
      });
      return plans.sort(SortHelpers.compareValues("name"));
    },
    bleActivation(state) {
      return state.bleActivation;
    },
    bleActivationModal(state) {
      return state.bleActivation.modal;
    },
    bleDevices(state) {
      if (state.bleDevices.length > 0) return state.bleDevices;
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
      if (!cache) {
        cache = {};
      }
      const email = localStorage.getItem("email");
      if (typeof cache[email] == "undefined" || !cache[email].hasOwnProperty("bleDevices")) return [];
      return cache[email].bleDevices;
    },
    bleResponse(state) {
      return state.bleActivation.response;
    },
    plugClosestExpirationTimestamp(state) {
      return state.plugClosestExpirationTimestamp;
    },
    ownerBleSessions(state) {
      if (state.ownerBleSessions.length > 0) return state.ownerBleSessions;
      return JSON.parse(localStorage.getItem("__plugzio_storage_owner_ble_sessions__"));
    },
    storedTime(state) {
      return {
        since: state.time.since ? dates.setSinceDate(state.time.since) : null,
        till: state.time.till ? dates.setTillDate(state.time.till) : null,
      };
    },
    newUser(state) {
      return state.count.plugs === 0 && state.count.plans === 0 && state.count.wallets === 0;
    },
    ownerBleCredentials(state) {
      try {
        if (Object.keys(state.ownerBleCredentials).length > 0) return state.ownerBleCredentials;
        let cache = JSON.parse(localStorage.getItem("__plugzio_storage_owner_ble_credentials__"));
        if (!cache) {
          cache = {};
        }
        const email = localStorage.getItem("email");
        if (!cache.hasOwnProperty(email)) return {};
        return cache[email];
      } catch (error) {
        return {};
      }
    },
    plugs: (state, getters, rootState) => {
      if (!state.plugs) return [];
      let plugs = state.plugs;
      const activeSessions = state.sessions.filter((session) => !session.endtime);

      plugs.map((plug) => {
        plug.active = !!activeSessions.find((a) => a.plug_id === plug.id);
        plug.active_state = plug.active || plug.plugmode === 1;

        plug.plan = null;
        if (!!plug.plan_id) plug.plan = state.plans.find((plan) => plan.id === plug.plan_id);
        if (plug.plan && typeof plug.plan.params == "string") plug.plan.params = JSON.parse(plug.plan.params);

        plug.access_settings = plug.ispublic ? "Public" : "Private";
        plug.access_plan = plug.ispublic && plug.plan ? plug.plan.params.description : "";

        plug.mode = "Always Off";
        plug.color = "#f44336";
        if (plug.plugmode == 1) {
          plug.mode = "Always On";
          plug.color = "#00acc1";
        } else if (plug.plugmode == 2) {
          plug.mode = "Smart";
          plug.color = "#FFA500";
        } else if (plug.plugmode >= 3) {
          plug.mode = "Bluetooth";
          plug.color = "#000";
        }

        const start = Vue.moment.unix(plug.start_timestamp).tz(rootState.Global.timezone);
        const expiry = Vue.moment.unix(plug.expiry_timestamp).tz(rootState.Global.timezone);
        const today = Vue.moment().tz(rootState.Global.timezone);
        const diff = expiry.diff(today, "days");
        plug.auto = plug.payment_wallet_id ? "ON" : "OFF";
        plug.auto_class = plug.payment_wallet_id ? "green--text" : "orange--text";
        plug.wallet = null;
        if (!!plug.payment_wallet_id) plug.wallet = state.wallets.find((wallet) => wallet.id == plug.payment_wallet_id);
        plug.start = start.format("DD-MMM-YYYY hh:mm:ss A");
        plug.expiry = expiry.format("DD-MMM-YYYY hh:mm:ss A");
        plug.diff = diff;

        plug.expired = "bill-expired";
        plug.remaining = "Disabled";
        plug.remaining_raw = 0;
        if (plug.state != 0) {
          plug.expired = diff <= 7 ? "bill-warning" : diff <= 30 ? "bill-notify" : "";
          plug.remaining_raw = plug.diff;
          plug.remaining = plug.diff < 1 ? `${Math.abs(plug.diff)} Days Expired` : plug.diff + " Days";
        }

        const { communication, communication_type } = DeviceHelpers.parseSerialNumber(plug.serial_no || "") || {}
        plug.communication = communication;
        plug.communication_type = communication_type

        plug.logs = [];
        plug.expanded = false;
        plug.busy = false;

        return plug;
      });
      // if (filters) return PlugHelpers.filter(plugs, filters);
      return plugs.sort(SortHelpers.compareValues("identifier"));
    },
    plugsCount(state) {
      return state.count.plugs;
    },
    plansCount(state) {
      return state.count.plans;
    },
    sessions: (state, getters, rootState) => {
      let sessions = state.sessions;
      const currentEpoch = Vue.moment().unix();
      const sessionClasses = ["plug-off-color", "plug-on-color", "plug-stale-color"];
      sessions = sessions.map((session) => {
        session.wallet = null;
        let wallet_id = state.Auth.role == "user" ? session.user_wallet_id : session.owner_wallet_id;
        if (state.Auth.role == "admin") {
          wallet_id = state.Admin.activeView == "userview" ? session.user_wallet_id : session.owner_wallet_id;
        }
        if (state.wallets && !!wallet_id) session.wallet = state.wallets.find((wallet) => wallet.id === wallet_id);
        session.active = !session.endtime;
        session.updated = session.endtime ? session.endtime : session.last_update_timestamp ? session.last_update_timestamp : session.starttime;
        if (session.updated < session.starttime) session.updated = session.starttime;
        if (session.endtime && session.endtime < session.starttime) session.endtime = session.starttime;

        session.status = !session.endtime ? "ON" : "OFF";
        session.stale_time = !session.endtime ? currentEpoch - session.updated : 0;
        session.statusCode = !session.endtime ? (session.stale_time >= window.SESSION_STALE_TIMEOUT / 1000 ? SESSION_STATUS.STALE : SESSION_STATUS.ON) : SESSION_STATUS.OFF;

        session.class = sessionClasses[session.statusCode];

        session.lapse = !session.endtime ? TimeHelpers.lapse(Vue.moment.unix(session.starttime), Vue.moment.unix(session.updated)) : "00:00:00";
        session.duration = !session.endtime ? session.lapse : TimeHelpers.lapse(Vue.moment.unix(session.starttime), Vue.moment.unix(session.endtime));
        session.duration_raw = !session.endtime ? session.updated - session.starttime : session.endtime - session.starttime;
        session.consumption = session.total_consumption ? (session.total_consumption / 1000).toFixed(3) + " kWh" : 0 + " kWh";
        session.amount = session.total_cost === 0 ? "0.00" : parseFloat(session.total_cost).toFixed(2);
        session.starttime_formatted = Vue.moment
          .unix(session.starttime)
          .tz(rootState.Global.timezone)
          .format("DD-MMM-YYYY hh:mm:ss A");
        session.endtime_formatted = session.endtime
          ? Vue.moment
              .unix(session.endtime)
              .tz(rootState.Global.timezone)
              .format("DD-MMM-YYYY hh:mm:ss A")
          : null;
        session.update_formatted = TimeHelpers.ago(session.updated, rootState.Global.timezone);
        
        const start_date = Vue.moment.unix(session.starttime).tz(rootState.Global.timezone).format("DD")
        const end_date = Vue.moment.unix(session.endtime).tz(rootState.Global.timezone).format("DD")
        session.graph_timeformat = "h:mm:ss A";
        if (start_date != end_date) {
          session.graph_timeformat = "MMM D - h:mm:ss A";
        }

        session.plug = session.plug ? session.plug : null;
        session.plug_identifier = session.plug_identifier ? session.plug_identifier : null;
        // session.timeline = session.timeline ? session.timeline : null;
        if (state.plugs && state.plugs.length > 0) {
          session.plug = state.plugs.find((plug) => plug.id === session.plug_id);
          session.plug_description = session.plug ? session.plug.description : null;
          if (!session.plug_identifier && session.plug) session.plug_identifier = session.plug.identifier;
        }
        // else {
        //   session.timeline = [
        //     {
        //       tag: "START",
        //       content: Vue.moment.unix(session.starttime).format("DD-MMM-YYYY hh:mm:ss A"),
        //       class: session.class,
        //       icon: "power",
        //     },
        //     {
        //       tag: session.endtime ? "END" : "UPDATED",
        //       content: session.endtime ? Vue.moment.unix(session.updated).format("DD-MMM-YYYY hh:mm:ss A") : Vue.moment().format("DD-MMM-YYYY hh:mm:ss A"),
        //       class: session.class,
        //       icon: session.endtime ? "power_off" : "power",
        //     },
        //   ];
        // }
        session.timeline = [
          {
            tag: "START",
            content: Vue.moment
              .unix(session.starttime)
              .tz(rootState.Global.timezone)
              .format("DD-MMM-YYYY hh:mm:ss A"),
            class: session.class,
            icon: "power",
          },
          {
            tag: session.endtime ? "END" : "UPDATED",
            content: session.endtime
              ? Vue.moment
                  .unix(session.updated)
                  .tz(rootState.Global.timezone)
                  .format("DD-MMM-YYYY hh:mm:ss A")
              : Vue.moment
                  .unix(session.last_update_timestamp)
                  .tz(rootState.Global.timezone)
                  .format("DD-MMM-YYYY hh:mm:ss A"),
            class: session.class,
            icon: session.endtime ? "power_off" : "power",
          },
        ];

        if (typeof session.plug_payment_access_plan == "string") session.plug_payment_access_plan = JSON.parse(session.plug_payment_access_plan);

        return session;
      });
      // if (filters) return SessionHelpers.filter(sessions, filters);
      return sessions;
    },
    wallets(state) {
      let wallets = state.wallets;
      if (!wallets) return [];
      return wallets.sort(SortHelpers.compareValues("description"));
    },
    walletsCount(state) {
      return state.count.wallets;
    },
  },
  actions: {
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    responsive({ commit }, payload) {
      commit("SET_RESPONSIVE", payload);
    },
    snackbar({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },
    snackbarHide({ commit }) {
      commit("SET_SNACKBAR_HIDE");
    },
    setTime({ commit, rootState }, payload) {
      const timezone = !!window.timezone ? window.timezone : rootState.Global.timezone;
      let set = dates.processDates(payload, timezone);
      commit("SET_TIME_SPAN", set);
      commit("SET_TIME", payload);
      if (timezone != rootState.Global.timezone) commit("Global/setTimezone", timezone, { root: true });
    },
    loadTransactions({ commit }) {
      console.log("load transactions");
    },
    loadWallets({ commit }) {
      console.log("load wallets");
    },
    loadPlugs({ commit }) {
      console.log("load plugs");
    },
    loadAccessPlans({ commit, state, getters}) {
      let apiPrefix = "user"
      if (state.Auth.role === "admin") {
        apiPrefix = "admin/user"
        if (getters["Admin/viewAsOwner"]) apiPrefix = "admin/owner"
      }
      if (state.Auth.role === "owner") apiPrefix = "owner"

      let requests = [Api.plugPaymentAccessPlans(apiPrefix, state.Auth.role === 'admin' ? {  ownerUsername: getters["Admin/viewAsOwner"].username } : {} )];
      
      const payload = {}
      if (state.Auth.role === "admin") {
        if (getters["Admin/viewAsOwner"]) payload.ownerUsername = getters["Admin/viewAsOwner"].username
        if (getters["Admin/viewAsUser"]) payload.username = getters["Admin/viewAsUser"].username
      }
      requests.push(Api.wallets(apiPrefix, payload));

      console.log("store: payment plans + wallets");

      Promise.all(requests)
        .then(([plans, wallets]) => {
          let accessPlans = plans.data || [];
          if (wallets && wallets.data) commit("SET_WALLETS", wallets.data || []);
          commit("SET_PLANS", accessPlans);
          accessPlans.length > 0 ? commit("SET_WITH_PLANS", true) : commit("SET_WITH_PLANS", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlugs({ commit, state, getters }) {
      const formData = getters["Admin/viewAsOwner"] ? { ownerUsername: getters["Admin/viewAsOwner"].username } : {};
      const apiPrefix = state.Auth.role == "admin" ? "admin/owner" : "owner";
      
      commit("SET_LOADING", true);
      Api.plugs(apiPrefix, formData)
        .then((plugs) => {
          commit("SET_COUNT_PLUGS", plugs ? plugs.length : 0);
          commit("SET_PLUGS", plugs || []);
          if (plugs) commit("SET_PLUG_CLOSEST_EXPIRATION_TIMESTAMP", plugs);
        })
        .catch((error) => console.log(error))
        .finally(() => commit("SET_LOADING", false))
    },
    updateWalletBalance({ commit, state, getters }) {
      const formData = getters["Admin/viewAsOwner"] ? { ownerUsername: getters["Admin/viewAsOwner"].username } : {};
      let apiPrefix = "user"
      if (state.Auth.role === "admin") {
        if (getters["Admin/viewAsOwner"]) apiPrefix = "admin/owner"
        if (getters["Admin/viewAsUser"]) apiPrefix = "admin/user"
      }
      if (state.Auth.role === "owner") apiPrefix = "owner"      
      
      commit("SET_LOADING", true);
      Api.wallets(apiPrefix, formData)
        .then((wallets) => {
          commit("SET_COUNT_WALLETS", wallets ? wallets.length : 0);
          commit("SET_WALLETS", wallets || []);
        })
        .catch((error) => console.log(error))
        .finally(() => commit("SET_LOADING", false))
    },

    countData({ commit, state, getters, rootState }) {
      const formData = getters["Admin/viewAsOwner"] ? { ownerUsername: getters["Admin/viewAsOwner"].username }  : state.Auth.role === "admin" ? { ownerUsername : rootState.Admin.managerview.owner_username } : {};

      let apiPrefix = "user"
      if (state.Auth.role === "admin") {
        if (getters["Admin/viewAsOwner"]) apiPrefix = "admin/owner"
        if (getters["Admin/viewAsUser"]) apiPrefix = "admin/user"
        if (!getters["Admin/viewAsOwner"] && !getters["Admin/viewAsUser"]) apiPrefix = "admin/owner"
      }
      if (state.Auth.role === "owner") apiPrefix = "owner"      
          
      let requests = [Api.plugs(apiPrefix, formData), Api.wallets(apiPrefix, formData)];

      if (state.count.plans === null) {
        requests.push(Api.plugPaymentAccessPlans(apiPrefix, formData));
        console.log("store: payment plans");
      }
      
      commit("SET_LOADING", true);
      Promise.all(requests)
        .then(([plugs, wallets, plans]) => {
          commit("SET_COUNT_PLUGS", plugs ? plugs.length : 0);
          commit("SET_PLUGS", plugs || []);
          commit("SET_COUNT_WALLETS", wallets ? wallets.length : 0);
          commit("SET_WALLETS", wallets || []);
          if (state.count.plans === null) {
            commit("SET_COUNT_PLANS", plans ? plans.length : 0);
            commit("SET_PLANS", plans);
          }
          if (plugs) commit("SET_PLUG_CLOSEST_EXPIRATION_TIMESTAMP", plugs);
          commit("SET_STATE", { initialized: true });
        })
        .catch((error) => console.log(error))
        .finally(() => commit("SET_LOADING", false))
    },
    countUserData({ commit, getters }) {
      const formData = getters["Admin/viewAsUser"] ? { username: getters["Admin/viewAsUser"].username } : {};
      const apiPrefix = getters["Admin/viewAsUser"] ? 'admin/user' : 'user';

      commit("SET_LOADING", true);
      Api.wallets(apiPrefix, formData)
        .then((wallets) => {
          commit("SET_COUNT_USER_WALLETS", wallets ? wallets.length : 0);
          commit("SET_WALLETS", wallets || []);
          commit("SET_STATE", { initialized: true });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => commit("SET_LOADING", false));
    },
    outletHighlight({ commit }, payload) {
      commit("SET_OUTLET_HIGHLIGHT", payload);
    },
    editPlug({ commit }, payload) {
      commit("SET_PLUG_EDIT", payload);
    },
    editOfflinePlug({ commit }, payload) {
      commit("SET_PLUG_OFFLINE_EDIT", payload);
    },
    setShowOwnerRegisterPlug({ commit }, payload) {
      commit("SET_SHOW_OWNER_REGISTER_PLUG", payload);
    },
    editAccessPlan({ commit }, payload) {
      commit("SET_ACCESS_PLAN_EDIT", payload);
    },
    editWallet({ commit }, payload) {
      commit("SET_WALLET_EDIT", payload);
    },
    editPlugUser({ commit }, payload) {
      commit("SET_PLUG_ACCESS_USER_EDIT", payload);
    },
    setBleActivation({ commit }, payload) {
      commit("SET_BLE_ACTIVATION", payload);
    },
    setBleActivationModal({ commit }, payload) {
      commit("SET_BLE_ACTIVATION_MODAL", payload);
    },
    setBleActivationActiveCommand({ commit }, payload) {
      commit("SET_BLE_ACTIVATION_ACTIVE_COMMAND", payload);
    },
    setBleCredential({ commit }, payload) {
      commit("SET_BLE_CREDENTIAL", payload);
    },
    setBleDevice({ commit }, payload) {
      commit("SET_BLE_DEVICE", payload);
    },
    setBleDevices({ commit }, payload) {
      commit("SET_BLE_DEVICES", payload);
    },
    setBleResponse({ commit }, payload) {
      commit("SET_BLE_RESPONSE", payload);
    },
    setBleSyncTime({ commit }, payload) {
      commit("SET_BLE_SYNC_TIME", payload);
    },
    setDeviceRegistrationSecretToken({ commit }, payload) {
      commit("SET_DEVICE_REGISTRATION_SECRET_TOKEN", payload);
    },
    setStats({ commit }, payload) {
      commit("SET_STATS", payload);
    },
    setExpenses({ commit }, payload) {
      commit("SET_EXPENSES", payload);
    },
    async setSessions({ commit }, payload) {
      if (!window.use_dummy_session_data) return commit("SET_SESSIONS", payload);

      let sessions = payload;
      try {
        commit("SET_LOADING", true)
        const response = await fetch("https://gist.githubusercontent.com/aryabawanta/0efdf26f541f5234c5901bfab80f3b3e/raw/4b7977e56d30475d10cc4364b694d9062ef72af2/dummy-big-session-data.json")
        sessions = JSON.parse(await response.text())
        commit("SET_SESSIONS", sessions);
        commit("SET_LOADING", false)
      } catch (error) {
        console.error("error set sessions", error)
        commit("SET_LOADING", false)
        commit("SET_SESSIONS", payload);
      }
    },
    setPlugs({ commit }, payload) {
      commit("SET_PLUGS", payload);
    },
    setPlugClosestExpirationTimestamp({ commit }, payload) {
      commit("SET_PLUG_CLOSEST_EXPIRATION_TIMESTAMP", payload);
    },
    setOwnerBleCredential({ commit }, payload) {
      commit("SET_OWNER_BLE_CREDENTIAL", payload);
    },
    setOwnerBleSessions({ commit }, payload) {
      commit("SET_OWNER_BLE_SESSIONS", payload);
    },
    setWalletItems({ commit }, payload) {
      commit("SET_WALLET_ITEMS", payload);
    },
    setWalletTransactions({ commit }, payload) {
      commit("SET_WALLET_TRANSACTIONS", payload);
    },
    setSpesificWalletTransactions({ commit }, payload) {
      commit("SET_SPESIFIC_WALLET_TRANSACTIONS", payload);
    },
    setRevenueTotal({ commit }, payload) {
      commit("SET_REVENUE_TOTAL", payload);
    },
    setWallets({ commit }, payload) {
      commit("SET_WALLETS", payload || []);
    },
    setPlans({ commit }, payload) {
      commit("SET_PLANS", payload || []);
    },
    resetCount({ commit }) {
      commit("RESET_COUNT");
    },
    resetWithPlans({ commit }) {
      commit("RESET_WITH_PLANS");
    },
    setUniversalLink({ commit }, payload) {
      commit("SET_UNIVERSAL_LINK", payload);
    },
    setFirebaseToken({ commit }, payload) {
      commit("SET_FIREBASE_TOKEN", payload);
    },
    setFirebaseMessage({ commit }, payload) {
      commit("SET_FIREBASE_MESSAGE", payload);
    },
    showFeedback({ commit }, payload) {
      commit("SHOW_FEEDBACK", payload);
    },
    onResume() {
      console.log("app resume");
    },
    onPause() {
      console.log("app paused");
    },
    async initialize({ commit, dispatch, rootState, rootGetters }) {
      if (!rootGetters["Auth/isAuthenticated"]) return
      commit("RESET")

      if (rootState.Auth.role === USER_ROLES.OWNER) await dispatch("OwnerProxy/initialize")
      else commit("OwnerProxy/setState", { initialized: true })
    
      dispatch("Global/initializePreferences")
      if (rootState.Auth.role !== "admin") dispatch(rootState.Auth.role == USER_ROLES.OWNER ? "countData" : "countUserData")
    },
    resetState({ commit }) {
      commit("RESET")
      this.dispatch("OwnerProxy/resetState")
    }
  },
});
