export default {
  namespaced: true,
  state: {
    filters: {},
    processedDevices: [],
    processingTimeout: 120,
  },
  getters: {
    processedDeviceByIdentifier: (state) => (identifier) => {
      return state.processedDevices.find((d) => d.identifier == identifier);
    },
  },
  mutations: {
    setProcessedDevices(state, payload) {
      state.processedDevices = payload;
    },
    // modify if exist, add if not exist
    modifyProcessedDevices(state, payload) {
      const index = state.processedDevices.findIndex((d) => d.identifier == payload.identifier);
      if (index == -1) return state.processedDevices.push(payload);
      state.processedDevices[index] = payload;
    },
    removeProcessedDevices(state, identifier) {
      state.processedDevices = state.processedDevices.filter((d) => d.identifier != identifier);
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
  },
  actions: {},
};
