export default {
    namespaced: true,
    state: {},
    getters: {
      hiddenDevices: (_, __, rootState) => { 
        if (rootState.Admin.managerview) return (rootState.Admin.managerview.preferences || {}).hiddenDevices || []
        return (rootState.Global.preferences || {}).hiddenDevices || []
      }, 
      visibleDevices: (_, getters, ___, rootGetters) => { 
        return rootGetters["plugs"].filter(w => !getters["hiddenDevices"].includes(w.id))
      }
    },
    mutations: {},
    actions: {},
  };
  