import RouteHelpers from "../../library/helpers/RouteHelpers";
import { capitalize } from "lodash-es";

export default {
  namespaced: true,
  state: {
    role: "user",
    email: localStorage.getItem("email"),
    accessToken: localStorage.getItem("key"),
  },
  getters: {
    getRoleName(state) {
      if (!state.role) return "";
      return capitalize(state.role).replace("Owner", "Management");
    },
    getRoleSetting(state) {
      const settings = {
        admin: {
          routePrefix: "admin-",
          urlPrefix: "admin/",
          color: "#263238",
        },
        owner: {
          routePrefix: RouteHelpers.isLocal ? "owner-" : "",
          urlPrefix: RouteHelpers.isLocal ? "owner/" : "",
          color: "#F08400",
        },
        user: {
          routePrefix: "",
          urlPrefix: "",
          color: "#2b8a2b",
        },
      };
      return settings[state.role];
    },
    isAuthenticated(state) {
      return !!state.accessToken;
    }
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    setState(state, payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          state[key] = payload[key];
        }
      }
    }
  },
  actions: {
    setRole({ commit }) {
      const role = RouteHelpers.isAdminUrl() ? "admin" : RouteHelpers.isOwnerUrl() ? "owner" : "user";
      commit("SET_ROLE", role);
    },
    redirectToLogin() {},
  },
};
