import LocalStorageHelpers from "@/library/helpers/LocalStorageHelpers";
import Vue from "vue";
import { ApiHelpers } from "@/library/helpers";
import Api from "@/library/apis/Api";

const defaultPersistentState = () => ({
  starttime: null,
  endtime: null,
  timezone: null,
});

export default {
  namespaced: true,
  state: {
    deviceview: null,
    managerview: null,
    activeView: null,
    userview: null,
    searchedUsers: null,
    persistents: defaultPersistentState,
  },
  getters: {
    formattedDeviceView: (state, getters, rootState, rootGetters) => {
      if (!state.deviceview) return null;
      return {
        ...rootGetters.plugs.find((p) => p.id == state.deviceview.id),
        ...state.deviceview,
      };
    },
    viewAsOwner: (state) => {
      if ((!state.managerview && !state.deviceview) || !state.activeView) return null;
      if (state.activeView == "managerview") {
        if (!state.managerview) return null;
        return {
          username: state.managerview ? state.managerview.owner_username : null,
          id: state.managerview ? state.managerview.id : null,
        };
      } else if (state.activeView == "deviceview") {
        if (!state.deviceview) return null;
        return {
          username: state.deviceview ? state.deviceview.owner_username : null,
          id: state.deviceview ? state.deviceview.owner_id : null,
        };
      }

      return null;
    },
    viewAsUser: (state) => {
      if (!state.userview || !state.activeView) return null;
      return {
        username: state.userview.username,
        id: state.userview.id,
      };
    },
    isDeviceRegistered: (state) => {
      if (state.activeView !== "deviceview") return true;

      if (state.activeView === "deviceview" && !state.deviceview) return false;

      return state.deviceview.id !== "not_registered";
    },
  },
  mutations: {
    setState(state, payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          state[key] = payload[key];
        }
      }
    },
    removeSearchedUser(state, search) {
      if (!state.searchedUsers) state.searchedUsers = [];
      const searchedUsers = state.searchedUsers.filter((user) => user.search != search);

      LocalStorageHelpers.setNestedItem("__plugzio_admin_storage__", ["searched_items", state.activeView, localStorage.getItem("email")], searchedUsers);

      state.searchedUsers = searchedUsers;
    },
    setDeviceView(state, payload) {
      state.deviceview = payload;
    },
    setSearchedUsers(state, search) {
      const datetime = Vue.moment().format("YYYY-MM-DD HH:mm:ss");
      if (!Array.isArray(state.searchedUsers)) {
        state.searchedUsers = [];
      }

      let searchedUsers = state.searchedUsers.filter((user) => user.search !== search);
      searchedUsers.unshift({ search, datetime });
      searchedUsers = searchedUsers.slice(0, 100);

      LocalStorageHelpers.setNestedItem("__plugzio_admin_storage__", ["searched_items", state.activeView, localStorage.getItem("email")], searchedUsers);
      state.searchedUsers = searchedUsers;
    },
  },
  actions: {
    setActiveView({ state }, route) {
      state.activeView = null;

      if (!route) route = "";

      // This action fetches data preferences/user history search related to the manager view,
      // where the device subscription doesn't have an active view state component.
      if (route.match(/managerview/) || route.match(/configure-device-subscriptions-time/) || route.match(/configure-device-subscriptions-plan/)) state.activeView = "managerview";
      else if (route.match(/userview/)) state.activeView = "userview";
      else if (route.match(/deviceview/)) state.activeView = "deviceview";
    },
    getSearchedUsers({ state }) {
      state.searchedUsers = LocalStorageHelpers.getNestedItem("__plugzio_admin_storage__", ["searched_items", state.activeView, localStorage.getItem("email")]);
    },
    resetState({ state }) {
      state.deviceview = null;
      state.managerview = null;
      state.userview = null;
    },
    async getPreferencesForView({ state, getters, commit }) {
      const view = getters.viewAsOwner || getters.viewAsUser;
      if (!view) return;

      const preferences = await Api.getPrefs(
        ApiHelpers.getApiPrefix("admin", state.activeView),
        ApiHelpers.getUsernamePayloadOfViewAs(state.activeView, getters.viewAsOwner, getters.viewAsUser)
      );
      if (preferences) {
        commit("setState", {
          [state.activeView]: {
            ...state[state.activeView],
            preferences,
          },
        });
      }
    },
  },
};
