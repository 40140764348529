export default {
  namespaced: true,
  state: {
    search: null,
    enableRegexSearch: false,
  },
  getters: {},
  mutations: {
    set(state, payload = {}) {
      for (const key in payload) {
        if (state.hasOwnProperty(key)) {
          state[key] = payload[key];
        }
      }
    },
  },
  actions: {},
};
