import { sortByProperty } from "@/library/helpers";
import { cloneDeep } from "lodash-es";
import Api from "@/library/apis/Api";
import { ApiHelpers } from "@/library/helpers";

const defaultState = {
  withdrawalProfiles: {
    achList: [],
    wireList: [],
    chequeList: [],
    paypalList: [],
  },
  topup: {
    mobikwik: {
      active: false,
      menu: null,
      balance: 0,
    },
  },
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters: {
    hiddenWallets: (_, __, rootState) => {
      if (rootState.Admin.managerview) return (rootState.Admin.managerview.preferences || {}).hiddenWallets || [];
      if (rootState.Admin.userview) return (rootState.Admin.userview.preferences || {}).hiddenWallets || [];
      return (rootState.Global.preferences || {}).hiddenWallets || [];
    },
    availableWallets: (_, __, rootState) => {
      return rootState.wallets.filter((wallet) => ["CAD", "USD"].includes(wallet.currency));
    },
    specificWallets: (_, getters, rootState) => {
      return rootState.wallets.filter((w) => !getters["hiddenWallets"].includes(w.id) && getters["availableWallets"].includes(w)).sort(sortByProperty("description"));
    },
    visibleWallets: (_, getters, rootState) => {
      return rootState.wallets.filter((w) => !getters["hiddenWallets"].includes(w.id)).sort(sortByProperty("description"));
    },
  },
  mutations: {
    setTopupMobikwikState(state, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          state.topup.mobikwik[key] = payload[key];
        }
      }
    },
    setWithdrawalProfiles(state, profiles) {
      state.withdrawalProfiles = cloneDeep(defaultState.withdrawalProfiles, profiles);
    },
    resetTopupMobikwikState(state) {
      state.topup.mobikwik = cloneDeep(defaultState.topup.mobikwik);
    },
  },
  actions: {
    async fetchWithdrawalProfiles({ commit, rootState, rootGetters, dispatch }) {
      if (!rootState.Admin.managerview && rootState.Auth.role !== "owner") return;
      try {
        const response = await Api.wallets(
          ApiHelpers.getApiPrefix(rootState.Auth.role, rootState.Admin.activeView),
          ApiHelpers.getUsernamePayloadOfViewAs(rootState.Admin.activeView, rootGetters["Admin/viewAsOwner"], rootGetters["Admin/viewAsUser"])
        );

        const profiles = {
          paypalList: [],
          wireList: [],
          chequeList: [],
          achList: [],
        };

        response.forEach((item) => {
          const parsedParams = typeof item.params === "string" ? JSON.parse(item.params) : item.params;
          const withdrawalProfile = parsedParams?.withdrawalProfile || {};

          if (Array.isArray(withdrawalProfile.paypalList)) {
            profiles.paypalList = [...profiles.paypalList, ...withdrawalProfile.paypalList];
          }
          if (Array.isArray(withdrawalProfile.wireList)) {
            profiles.wireList = [...profiles.wireList, ...withdrawalProfile.wireList];
          }
          if (Array.isArray(withdrawalProfile.chequeList)) {
            profiles.chequeList = [...profiles.chequeList, ...withdrawalProfile.chequeList];
          }
          if (Array.isArray(withdrawalProfile.achList)) {
            profiles.achList = [...profiles.achList, ...withdrawalProfile.achList];
          }

          if (rootState.walletEdit && rootState.walletEdit.id === item.id) {
            dispatch("editWallet", item, { root: true });
          }
        });
        commit("setWithdrawalProfiles", profiles);
      } catch (error) {
        console.error("Error fetching withdrawal profiles:", error);
      }
    },
  },
};
