import { Capacitor } from "@capacitor/core";

const href = window.location.href;
const subDomain = window.location.host.split(".")[0];
const subPath = window.location.pathname !== "/" ? window.location.pathname : "";
const isApp = Capacitor.isNativePlatform();
const server = process.env.VUE_APP_SERVER || "production";
console.log("SERVER:", server);

// No settings required
window.UI_URL = subPath;
window.API_URL = "";
window.LOG_API_URL = "https://logs.plugz.io";

// General settings
window.REQUEST_TIMEOUT = 30000;
window.SESSION_REFRESH_INTERVAL = 300000;
window.SESSION_STALE_TIMEOUT = 1800000;
window.DEFAULT_PAYPAL_CLIENT_ID = "AYiAZnnlwt77oAtHFGbm-xhKn-ZL8z9Tee3On2PYiPExJkUevESLnJLvqH1ImIppZnQcAhgQ5yk-lwZ6";
window.PAYPAL_CLIENT_ID = "AYiAZnnlwt77oAtHFGbm-xhKn-ZL8z9Tee3On2PYiPExJkUevESLnJLvqH1ImIppZnQcAhgQ5yk-lwZ6";
window.PAYPAL_CLIENT_ID_SANDBOX = "AZqjBbEmxkFZnx_1C9lHT3J3dlMHoU3ZctQ4VgKQPjXuPmXI7iUb6xfZ3Il5j96i-C0KDqbUaItVaTSx";
window.CAROUSEL_TIME = 30000;
window.mobikwikSettings = {
  merchantName: process.env.VUE_APP_MOBIKWIK_MERCHANT_NAME,
  merchantId: process.env.VUE_APP_MOBIKWIK_MERCHANT_ID,
  endpoint: process.env.VUE_APP_MOBIKWIK_API_URL,
  openInTab: false,
}

// Settings override
if (subDomain === "my" || subDomain === "manage") {
  if (subPath) {
    console.log("Master with Production Database");
    // https://my.plugz.io/demo-contractor#/login and https://manage.plugz.io/demo-contractor#/login

    window.PAYPAL_CLIENT_ID = window.PAYPAL_CLIENT_ID_SANDBOX;
  } else {
    console.log("Production with Production Database");
    // https://my.plugz.io/ and https://manage.plugz.io/
  }
} else {
  if (subPath) {
    console.log("Master with Staging Database");
    // https://api-staging.plugz.io/demo-contractor#/

    window.PAYPAL_CLIENT_ID = window.PAYPAL_CLIENT_ID_SANDBOX;
  } else {
    console.log("Release Candidate with Staging Database");
    // https://api-staging.plugz.io/#/owner/register and https://api-staging.plugz.io/#/register

    window.PAYPAL_CLIENT_ID = window.PAYPAL_CLIENT_ID_SANDBOX;
  }
}

// Local dev only
if ((href.match(/localhost/) || server == "local") && !isApp) {
  // window.API_URL = "https://api-staging.plugz.io";
  window.API_URL = "http://localhost:8080";
  window.PAYPAL_CLIENT_ID = window.PAYPAL_CLIENT_ID_SANDBOX;
}

// App settings override (full api url required)
if (isApp) {
  window.API_URL = server == "production" ? "https://my.plugz.io" : "https://api-staging.plugz.io";
  if (server != "production") window.PAYPAL_CLIENT_ID = window.PAYPAL_CLIENT_ID_SANDBOX;
  else window.PAYPAL_CLIENT_ID = window.DEFAULT_PAYPAL_CLIENT_ID;
}

console.log(`sub: ${subDomain}`);
