import Api from "@/library/apis/Api";
import { cloneDeep } from "lodash-es";

const localStorageKey = "__plugzio_owner_proxy__"

export default {
  namespaced: true,
  state: {
    accessGivenToUsers: [],
    // need to differentiate between accessReceivedFromUsers and canActAsUsers
    // because accessReceivedFromUsers will consist of data LOGGED IN owner and when owner act as other owner
    // and canActAsUsers is the list of users that LOGGED IN owner can act as
    accessReceivedFromUsers: [],
    canActAsUsers: [],
    actAs: null,
    initialized: false
  },
  getters: {
    activeActAsUser(state) {
      const activeActAs = state.canActAsUsers.find(user => user.owner_username === state.actAs)
      if (!activeActAs) return null
      return {
        ...activeActAs,
        permissions: JSON.parse(activeActAs?.rights || "null")
      }
    },
    initialized(state, _, __, rootGetters) {
      return !rootGetters["Auth/isAuthenticated"] || state.initialized
    }
  },
  mutations: {
    setState(state, payload) {
      for (const key in payload) {
        if (state.hasOwnProperty(key)) {
          state[key] = cloneDeep(payload[key]);
        }
      }
    },
  },
  actions: {
    resetState({ dispatch, commit }) {
      dispatch("setActAs", null)
      commit("setState", { accessGivenToUsers: [], canActAsUsers: [] })
    },
    async initialize({ commit, dispatch, state }) {
      commit('setState', { initialized: false });
      await dispatch('fetchCanActAsUsers', true);
      const persistentState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
      if (persistentState.actAs) {
        let actAs = persistentState.actAs;
        if (!state.canActAsUsers.find(user => user.owner_username === persistentState.actAs)) {
          actAs = null
        }
        commit('setState', { actAs });
      }
      commit('setState', { initialized: true });
    },
    async fetchProxies({ state, commit, rootState }) {
      if (!rootState.Admin.managerview && rootState.Auth.role !== 'owner') return;

      const accessGivenToUsers = await Api.proxyProxies(rootState.Auth.role, {});
      commit('setState', { accessGivenToUsers });
    },
    async fetchCanActAsUsers({ commit, rootState }, updateCanActAsUsers = false) {
      if (!rootState.Admin.managerview && rootState.Auth.role !== 'owner') return;

      const proxyOwners = await Api.proxyOwners(rootState.Auth.role, {}, !updateCanActAsUsers) || [];
      commit('setState', updateCanActAsUsers ? { canActAsUsers: proxyOwners } : { accessReceivedFromUsers: proxyOwners });
    },
    setActAs({ commit }, actAs) {
      commit('setState', { actAs });

      const persistentState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
      if (actAs === null) {
        delete persistentState.actAs;
        if (!Object.keys(persistentState).length) localStorage.removeItem(localStorageKey);
        return
      }

      persistentState.actAs = actAs;
      localStorage.setItem(localStorageKey, JSON.stringify({ ...persistentState }));
    }
  },
};
