import raw_timezones from "./../../data/timezones.json";
import { LOCALSTORAGE_KEY } from "@/constants"
import LocalStorageHelpers from "@/library/helpers/LocalStorageHelpers"
import Api from "@/library/apis/Api";
import { ApiHelpers, LocationHelpers } from "@/library/helpers"
import { Geolocation } from '@capacitor/geolocation';

const defaultValues = {
  globalDialog: {
    show: false,
    title: null,
    content: null,
    showConfirm: true,
    confirmText: "OK",
    onConfirmed: () => {},
    showCancel: true,
    cancelText: "Cancel",
    onCanceled: () => {},
    onClosed: () => {},
  },
  intercom: {
    show: false,
    showEmailForm: false,
    email: localStorage.getItem("email")
  }
}

export default {
  namespaced: true,
  state: {
    timezone: null,
    offline: false,
    showSidebar: false,
    offlineModeSnackbar: {
      show: false,
      message: null,
      timeout: 3000,
      color: "#212121",
    },
    showOpenAppSnackbar: false,
    reloadOnStateChange: true,
    preferences: {},
    country: null,
    globalDialog: defaultValues.globalDialog,
    location: {
      latitude: null,
      longitude: null,
    },
    intercom: defaultValues.intercom,
    apiCalls: []
  },
  getters: {
    getTimezoneText(state) {
      if (!state.timezone) return null;
      const timezones = raw_timezones.map((v) => ({ name: v.text, id: v.value }));
      const timezone = timezones.find((tz) => tz.id == state.timezone);
      if (!timezone) return null;
      return timezone.name;
    },
    reloadOnStateChange(state, getters, rootState) {
      return state.reloadOnStateChange && rootState.Session.processedDevices.length === 0
    },
    countryCode(state) {
      return (state.country || {}).code
    }
  },
  mutations: {
    setTimezone(state, tz) {
      state.timezone = tz;
      console.log("Timezone changed:", tz);
    },
    setOffline(state, payload) {
      const { offline, offlineModeSnackbar } = payload
      if (offline != null) state.offline = offline
      if (!offlineModeSnackbar) return
      for (const key in offlineModeSnackbar) {
        if (state.offlineModeSnackbar.hasOwnProperty(key)) {
          state.offlineModeSnackbar[key] = offlineModeSnackbar[key];
        }
      }
    }, 
    setShowOpenAppSnackbar(state, show) {
      state.showOpenAppSnackbar = show;
    },
    setReloadOnStateChange(state, value) {
      state.reloadOnStateChange = value
    },
    setState(state, payload) {
      const saveToLocalStorageKeys = []
      for (const key in payload) {
        if (Object.hasOwnProperty.call(state, key)) {
          if (Array.isArray(state[key])) state[key] = payload[key]
          else state[key] = typeof state[key] === "object" && Object.keys(payload[key] || {}).length > 0 ? { ...state[key], ...payload[key] } : payload[key]
          
          if (saveToLocalStorageKeys.includes(key))
            LocalStorageHelpers.setNestedItem(LOCALSTORAGE_KEY, [key], state[key])
        }
      }
    },
  },
  actions: {
    init({ dispatch }) {
      dispatch("initWindow");
      dispatch("initTimezone");
      dispatch("initLocation");
    },
    initWindow({ commit }) {
      // Init window variable/functions, most of them will be used in development and testing
      window.changeCountryCode = (countryCode) => {
        const country = { code: countryCode, name: new Intl.DisplayNames(['en'], {type: 'region'}).of(countryCode) }
        commit("setState", { country });
        return "Changed country to " + JSON.stringify(country)
      }
      window.changeMobikwikSettings = (payload = {}) => {
        window.mobikwikSettings = { ...window.mobikwikSettings, ...payload }
        return "Changed Mobikwik settings to " + JSON.stringify(window.mobikwikSettings)
      }
    },
    initTimezone({ commit }) {
      const timezones = raw_timezones.map((v) => ({ name: v.text, id: v.value }));
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log("Timezone detected:", timezone);
      if (!timezone) timezone = "UTC";
      const selectedTimezone = timezones.find((tz) => tz.id == timezone);
      commit("setTimezone", selectedTimezone ? selectedTimezone.id : timezone);
    },
    async initLocation({ commit }) {
      // If web, use navigator.geolocation
      if (!Capacitor.isNativePlatform()) {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          onSuccessGetLocation(coords)
        }, (error) => {
          console.log("Failed to get location: ", error.message)
        });
      } else {
        try {
          const { coords } = await Geolocation.getCurrentPosition();
          onSuccessGetLocation(coords)
        } catch (error) {
          console.log("Failed to get location: ", error.message)
        }
      }

      function onSuccessGetLocation(coordinates) {
        let { latitude, longitude } = coordinates
        console.log("Detected location (latitude longitude):", latitude, longitude, ", getting country...")
        const country = LocationHelpers.getCountryByLatitudeAndLongitude(latitude, longitude)
        console.log("Detected country", country)
        commit("setState", { country, location: { latitude, longitude } });
      }
    },
    async initializePreferences({ rootState, commit, state }) {
      if (rootState.Auth.role === "admin") return

      const preferences = await Api.getPrefs(ApiHelpers.getApiPrefix(rootState.Auth.role))
      if (preferences) {
        commit("setState", { preferences })
      }
    },
    setTimezone({ commit, rootState, dispatch }, tz) {
      commit("setTimezone", tz);
      dispatch("setTime", rootState.time, { root: true });
    },
    reset({ state, commit }, keys = []) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Object.prototype.hasOwnProperty.call(state, key)) {
          let values = defaultValues[key]
          if (!values) continue
          commit("setState", { [key]: values })
        }
      }
    },
    toggleIntercom({ state, commit, rootGetters }) {
      if (!rootGetters["Auth/isAuthenticated"] && !state.intercom.show) {
        return commit("setState", { intercom: { ...state.intercom, showEmailForm: true } })
      }
      
      return commit("setState", { intercom: { ...state.intercom, show: !state.intercom.show } })
    }
  },
};
